import React, { useEffect, useState } from "react";
import {
  conditionalRendering,
  convertEnumToLabel,
  getLabelFromEnum,
  showErrorMessage,
  showToastMessage,
} from "../../../../../utils/helpers";
import axiosService from "../../../../../init/axios";
import { APIConfig } from "../../../../../utils/constants/api.constants";
import LoadingPanel from "../../../../common/LoadingPanel";

const TripInvoices = ({ tripId, stage }) => {
  const [tripInvoices, setTripInvoices] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getInvoices();
  }, []);

  const getInvoices = async () => {
    setLoading(true);
    await axiosService
      .get(
        process.env.REACT_APP_TRIP_BASE_URL +
          APIConfig.tripManagement.getInvoices(tripId)
      )
      .then((data) => {
        setLoading(false);
        setTripInvoices(data?.data?.invoices);
      })
      .catch((error) => {
        setLoading(false);
        showErrorMessage(error);
      });
  };

  const onInvoiceClick = async (filePath) => {
    setLoading(true);
    if (!filePath) {
      showToastMessage(`Invoice not available.`, false);
      setLoading(false);
    } else {
      await axiosService
        .get(
          process.env.REACT_APP_DRIVER_BASE_URL +
            APIConfig.driverManagement.getDriverDocumentImage +
            "?file-path=" +
            filePath
        )
        .then((data) => {
          if (data?.data) {
            openInvoice(data?.data?.preSignedUrl);
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          showErrorMessage(error);
        });
    }
  };

  const openInvoice = async (filePath) => {
    if (filePath) {
      window.open(filePath);
    }
  };

  return (
    <div className="trip-invoices">
      {conditionalRendering(
        ["TRIP_COMPLETED", "TRIP_CANCELED"],
        stage,
        <>
          {loading ? (
            <LoadingPanel />
          ) : (
            <>
              {tripInvoices?.length > 0 ?
                tripInvoices.map((invoice) => (
                  <div className="row mt-2 mb-2" key={invoice?.fileName}>
                    <div className="col-sm-2">
                      <span
                        onClick={(e) => onInvoiceClick(invoice?.filePath)}
                        style={{ color: "blue", cursor: "pointer" }}
                      >
                        {invoice?.fileName}
                      </span>
                    </div>
                  </div>
                )):<span>No Invoice generated for this trip.</span>}
            </>
          )}
        </>,
        <div>
          Invoice not available as trip stage is{" "}
          <b>{convertEnumToLabel(stage)}</b>.
        </div>
      )}
    </div>
  );
};

export default TripInvoices;
