export const APIConfig = {
  tripManagement: {
    getAllTrips: "/admin/trip/all",
    getTripDetails: (tripId) => "/admin/trip/" + tripId,
    getTripPricing: (tripId) => "/admin/trip/" + tripId + "/pricing",
    getCandidateDrivers: "/admin/candidate-drivers",
    getReservedDrivers: "/admin/reserved-drivers",
    assignReservedDriver: (tripId) =>
      "/admin/trip/" + tripId + "/assign-driver",
    getTripInsuranceDetails: (tripId) => "/admin/trip/" + tripId + "/documents",
    getTripInsuranceImage: (tripId) => "/admin/trip/" + tripId + "/doc",
    getPaymentLogs: (tripId) => "/admin/trip/" + tripId + "/payment-logs",
    getTripStatusLogs: (tripId) => "/admin/trip/" + tripId + "/status-logs",
    getAllocationLogs: (tripId) =>
      "/admin/trip/" + tripId + "/allocation-audit",
    getRevenueStreamLogs: (tripId) => "/admin/trip/" + tripId + "/pricing",
    markDnf: (tripId) => "/admin/trip/" + tripId + "/dnf",
    configData: "/trip/config/data",
    getWaiverDetails: (tripId) => "/admin/trip/" + tripId + "/waiver",
    saveWaiverDetails: (tripId) => "/admin/trip/" + tripId + "/waiver",
    createWaiver: (tripId) => "/admin/trip/" + tripId + "/waiver",
    getAllRefunds: (tripId) => "/admin/trip/" + tripId + "/refund/all",
    createRefund: (tripId) => "/admin/trip/" + tripId + "/refund",
    updateRefund: (tripId, refundId) =>
      "/admin/trip/" + tripId + "/refund/" + refundId,
    getInvoices: (tripId) => "/admin/trip/" + tripId + "/invoices",
    getRegions: "/trip/config/data",
    getTripStats: "/admin/trip/stats",
    getDriversTripFit: (tripId) => `/admin/trip/${tripId}/allocation-fit`,
    allocateDriver: (tripId) => `/admin/trip/${tripId}/allocate-driver`,
    allocateDriverB2C: (tripId, driverId, validate) =>
      `/admin/trip/${tripId}/${driverId}?validate=${validate}`,
    getChecklistData: (tripId, checklistId) =>
      `/admin/trip/${tripId}/${checklistId}`,
    getChecklistDetails: (tripId, checklistId) =>
      `/admin/trip/${tripId}/get-checklist/${checklistId}`,
    overrideOTP: (tripId, checkListId, clientTaskId) =>
      `/admin/trip/${tripId}/bypass-checklist-task/${checkListId}/${clientTaskId}`,
    rescheduleTrip: (tripId) => `/admin/trip/${tripId}`,
    getCallHistoryData: (tripId) =>`/admin/call-logs/${tripId}`,
    getCallHistoryAudio: (url) =>`/admin/call-recording?url=${url}`,
    getDisputeLogsData: (tripId)=>`/admin/trip/${tripId}/disputes/all`,
    getRefundDetailsData:(tripId, transactionId)=>`/admin/trip/${tripId}/refund/${transactionId}/logs`,
    cancelRefund:(tripId, transactionId)=>`/admin/trip/${tripId}/refund/${transactionId}/cancel`
  },
  driverManagement: {
    getAllDrivers: "/admin/driver/all",
    getMultipleDriverDetails: "/admin/driver",
    getDriverDetails: (driverId) => "/admin/driver/" + driverId,
    getDriverDocumentImage: "/admin/document",
    saveDriverDetails: (driverId) => "/admin/driver/" + driverId,
    saveDriverAdditionalDetails: (driverId) =>
      "/admin/driver/" + driverId + "/additional-details",
    getDriverAccounts: "/admin/driver/ledger",
    getDriverAccountDetails: (driverId) =>
      "/admin/driver/" + driverId + "/ledger",
    getDriverActivityStatus: "/admin/driver/activity-status",
    getDriverOnlineActivityList: (driverId) =>
      "/admin/driver/" + driverId + "/timesheet",
    uploadDriverContract: "/admin/upload-doc",
    getPennyDropLogs: (driverId) =>
      "/admin/driver/" + driverId + "/penny-drop-logs",
    getDriverPayoutBatch: "/admin/payout-batch",
    approvePayoutBatch: (batchId) => "/admin/payout-batch/" + batchId,
    getDriverPayoutBatchFile: (batchId) =>
      "/admin/payout-batch/" + batchId + "/export",
    uploadDriverPayoutFile: "/admin/upload-payout-doc",
    approvePayoutLevelFile: "/admin/upload-payout-doc",
    saveDriverBankDetails: (driverId) =>
      "/admin/driver/" + driverId + "/bank-details",
    getDriverStats: "/admin/driver/stats",
    getDriverTimeline: (driverId, date) =>
      `/admin/driver/${driverId}/timeline?date=${date}`,
    getDriverUpcomingTimeline: (driverId, date) =>
      `/admin/driver/${driverId}/scheduled/trips?date=${date}`,
    getDriverForTripB2B: (tripId) => `/admin/filter/drivers?tripId=${tripId}`,
    getDriverForTripB2C: (tripId) => `/admin/trip/${tripId}/drivers`,
    getTripPath: (driverId, tripId) =>
      `/admin/driver/${driverId}/trip/${tripId}/path`,
    getBroadcastedDrivers: (tripId) => `/admin/${tripId}/broadcasted`,
  },
  pricingManagement: {
    getPricingConfig: "/admin/fare/config/PRICING_AND_DILUTER_CONFIG_V2",
    savePricingConfig: "/admin/fare/config/PRICING_AND_DILUTER_CONFIG_V2",
    getPricingDiluterConfig: "/admin/fare/config/GCD_CONFIG",
    savePricingDiluterConfig: "/admin/fare/config/GCD_CONFIG",
    getPricingLogs: "/admin/fare/config/logs",
    getConfigDifference: (configType, configVersion) =>
      "/admin/fare/config/diff/" + configType + "/" + configVersion,
    getPricingVAC: (derivedTripType, regionId) =>
      `/admin/fare/config/VAC_CONFIG?derivedTripType=${derivedTripType}&regionId=${regionId}`,
    changePricingLogs: "/admin/fare/config/VAC_CONFIG",
  },
  bulkOperationsManagement: {
    uploadBulkOperation: "/admin/bulk-ops",
    getBulkOperation: "/admin/bulk-ops",
  },
  couponManagement: {
    getCouponDetails: (couponCode) => "/admin/coupon/" + couponCode,
    getCouponStatusOfCustomer: "/admin/user-coupon/status",
    issueNewCouponToCustomer: "/admin/user-coupon/issue",
    getCustomerCouponLogs: "/admin/user-coupon/logs",
  },
  customerManagement: {
    getCustomerDetails: "/admin/customer/details",
    blockCustomer: "/admin/customer/block",
    unBlockCustomer: (mobileNumber) =>
      "/admin/customer/unblock?mobileNumber=" + mobileNumber,
  },
  authManagement: {
    login: "/auth/admin/login",
  },
  apacheSuperset: {
    GUEST_TOKEN: "/api/v1/security/guest_token/",
    CSRF_TOKEN: "/api/v1/security/csrf_token/",
  },
  subscription: {
    getAllSubscriptions: "/admin/subscription",
    getSubscriptionStats: "/admin/subscription/stats",
    getSubscriptionDetails: (id) => "/admin/subscription/" + id,
    getSubscriptionCycleDetails: (subId, cycleId) =>
      `/admin/subscription/${subId}/cycle/${cycleId}`,
    getSubscriptionCycleDayDetails: (subId, tripId) =>
      `/admin/subscription/${subId}/trip/${tripId}`,
    assignDriverSubscriptionLevel: "/admin/subscription/assign-driver",
    getAllDriversSubscriptions: "/admin/driver/subscription/all",
  },
  clients: {
    getClients: "/admin/clients/all",
    getClientDetails: (clientId) => `/admin/client/${clientId}`,
    getClientsAddresses: (clientId) => `/admin/client/${clientId}/addresses`,
    getClientsCreateAddress: (clientId) => `/admin/client/${clientId}/address`,
    getClientUpdateAddress: (clientId, addressId) =>
      `/admin/client/${clientId}/address/${addressId}`,
  },
  ums: {
    login: "/api/v1/sso/login",
    logout: "/api/v1/token/invalidate",
    getUserDetails: "/api/v1/users/profile",
  },
};
