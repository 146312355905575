import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import React, { useState } from "react";
import { process } from "@progress/kendo-data-query";
import { APIConfig } from "../../../../../utils/constants/api.constants";
import GridLoader from "../../../../common/GridLoader";
import { Button } from "@progress/kendo-react-buttons";
import RefundLogs from "./RefundLogs";
import {
  conditionalRendering,
  convertEnumToLabel,
  timeConverter,
} from "../../../../../utils/helpers";
// import AddOrUpdateRefund from "./AddOrUpdateRefund";

const PaymentLogs = ({
  handleModalOpener = () => {},
  setRefundStatusUpdate,
  setSelectedRefundDetails,
  refresh,
  refundDataState,
  setRefundDataState,
  tripId,
  stage,
  clientType,
  setRefundSelectedRow,
  setRefresh
}) => {
  const baseUrl =
    process.env.REACT_APP_TRIP_BASE_URL +
    APIConfig.tripManagement.getPaymentLogs(tripId);
  const [paymentLogs, setPaymentLogs] = useState({
    data: [],
    total: 0,
  });
  const [dataState, setDataState] = useState({
    take: 10,
    skip: 0,
  });
  const IDCell = (props) => <td>{props?.dataItem?.orderId || "--"}</td>;

  const GenericCell = (props, transformFn) => {
    const { dataItem, field } = props;
    return <td>{transformFn(dataItem?.[field])}</td>;
  };

  const columns = [
    {
      id: "orderId",
      field: "orderId",
      title: "Order Id",
      cell: IDCell,
    },
    {
      id: "paymentMode",
      field: "paymentMode",
      title: "Payment Mode",
      cell: (props) => GenericCell(props, convertEnumToLabel),
    },
    {
      id: "createdAt",
      field: "createdAt",
      title: "Created At",
      cell: (props) => GenericCell(props, timeConverter),
    },
    {
      id: "updatedAt",
      field: "updatedAt",
      title: "Updated At",
      cell: (props) => GenericCell(props, timeConverter),
    },
    {
      id: "paymentStatus",
      field: "paymentStatus",
      title: "Payment Status",
      cell: (props) => GenericCell(props, convertEnumToLabel),
    },
  ];

  const dataStateChange = (e) => {
    setDataState(e.dataState);
  };
  const dataReceived = (dataObj) => {
    setPaymentLogs(dataObj.data.paymentDetails);
  };

  const openRefundModal = () => {
    handleModalOpener("addOrUpdatedRefundModal");
  };

  const onUpdateRefund = (e, props) => {
    setRefundStatusUpdate(true);
    setSelectedRefundDetails(props?.dataItem);
    openRefundModal();
  };

  return (
    <div className="payment-logs-table">
      <h6>Payment Logs</h6>
      <Grid
        filterable={false}
        sortable={false}
        pageable={false}
        {...dataState}
        data={paymentLogs}
        onDataStateChange={dataStateChange}
      >
        {columns.map((column) => (
          <Column
            key={column?.id}
            field={column?.field}
            title={column?.title}
            cell={column.cell}
          />
        ))}
      </Grid>

      <GridLoader
        baseUrl={baseUrl}
        columns={columns}
        dataState={dataState}
        onDataReceived={dataReceived}
      />
      {conditionalRendering(
        ["TRIP_COMPLETED", "TRIP_CANCELED"],
        stage,
        <div className="refund-section my-4">
          <Button
            themeColor={"primary"}
            type={"submit"}
            onClick={() => handleModalOpener("addOrUpdatedRefundModal")}
          >
            + Add Refund
          </Button>
          <br />
          <div className="refund-logs my-3">
            <h6>Refund Logs</h6>
            <RefundLogs
              tripId={tripId}
              refresh={refresh}
              dataState={refundDataState}
              setDataState={setRefundDataState}
              onUpdateRefund={onUpdateRefund}
              clientType={clientType}
              handleModalOpener={handleModalOpener}
              setRefundSelectedRow={setRefundSelectedRow}
              setRefresh={setRefresh}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default PaymentLogs;
