import React, { useEffect, useState } from "react";
import axiosService from "../../../../../init/axios";
import { APIConfig } from "../../../../../utils/constants/api.constants";
import LoadingPanel from "../../../../common/LoadingPanel";
import {
  showErrorMessage,
  timeConverter,
} from "../../../../../utils/helpers";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
function ExotelLog({ handleModalOpener,  setSelectedRow, tripId }) {
  const [callData, setCallData] = useState([]);
  const [loading, setLoading] = useState(false);
  
  const columns = [
    {
      id:"referenceId",
      field:"id",
      title:"Reference Id",
    },
    {
      id:"driverId",
      field:"driverId",
      title:"Driver Id",
      cell:(props)=>(
        <td>{props.dataItem?.driverId || "NA"}</td>
      )
    },
    {
      id: "callInitiatedBy",
      field: "callInitiatedBy",
      title: "Initiated By",
      cell:(props)=>(
        <td className="tw-capitalize">{props.dataItem?.callInitiatedBy?.toLowerCase()}</td>
      )
    },
    {
      id: "callInitiatedAt",
      field: "callInitiatedAt",
      title: "Time",
      cell: (props) => (
        <td>{timeConverter(props.dataItem?.callInitiatedAt)}</td>
      ),
    },
    {
      id: "status",
      field: "status",
      title: "Call Status",
      cell:(props)=>(
        <td className="tw-capitalize">{ props.dataItem?.status?.toLowerCase()}</td>
      )
    },
    {
      id: "recording_url",
      field: "recording_url",
      title: "Recording",
      cell: (props) => (
        <td>
          <Button
            themeColor="primary"
            fillMode="solid"
            disabled={!props.dataItem?.recordingUrl}
            onClick={() => {
              handleCallHistoryModal(props.dataItem);
              handleModalOpener("callHistoryAudioModal");
            }}
          >
            Open Recording
          </Button>
        </td>
      ),
      width:160
    },
  ];

  // Toggling the modal by setting the selected row.
  const handleCallHistoryModal = (row) => {
    setSelectedRow(row ? row : null);
  };
  const getCallHistoryData = async () => {
    setLoading(true);
    await axiosService
      .get(
        process.env.REACT_APP_TRIP_BASE_URL +
          APIConfig.tripManagement.getCallHistoryData(tripId)
      )
      .then((data) => {
        setCallData(data?.data?.callLogs);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        showErrorMessage(error);
      });
  };
  useEffect(() => {
    getCallHistoryData();
  }, []);

  return (
    <div>
      {loading ? (
        <LoadingPanel />
      ) : (
        <div>
          <Grid data={callData}>
            {columns.map((column) => (
              <Column
                key={column?.id} // unique key for each column
                field={column?.field}
                title={column?.title}
                filterable={column?.filterable || false}
                width={column?.width}
                cell={column?.cell || undefined} // apply custom cell if defined
              />
            ))}
          </Grid>
        </div>
      )}
    </div>
  );
}

export default ExotelLog;
