import React, { useEffect, useState } from "react";
import LoadingSpinner from "../../../../common/LoadingSpinner";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import axiosService from "../../../../../init/axios";
import { APIConfig } from "../../../../../utils/constants/api.constants";
import {
  convertEnumToLabel,
  showErrorMessage,
  timeConverter,
} from "../../../../../utils/helpers";

function DisputeLogs({ tripId }) {
  const [loading, setLoading] = useState(false);
  const [disputeLogs, setDisputeLogs] = useState([]);

  const fetchDisputeLogs = async () => {
    setLoading(true);
    try {
      const { data } = await axiosService.get(
        process.env.REACT_APP_TRIP_BASE_URL +
          APIConfig.tripManagement.getDisputeLogsData(tripId)
      );
      if (data) setDisputeLogs(data);
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDisputeLogs();
  }, []);

  /**
   * Generic cell renderer
   * @param {Function} extractor - Function to extract and format the data (optional)
   * @param {String} defaultValue - Fallback value if data is undefined (optional)
   */
  const createCellRenderer =
    (extractor = (data) => data, defaultValue = "--") =>
    (props) => {
      const value = extractor(props?.dataItem);
      return <td>{value || defaultValue}</td>;
    };

  // Column definitions
  const columns = [
    {
      id: "id",
      field: "disputeId",
      title: "Dispute Id",
      width: 150,
    },
    {
      id: "natureOfDispute",
      field: "disputeType",
      title: "Nature of Dispute",
      width: 250,
      cell: createCellRenderer((data) => convertEnumToLabel(data?.disputeType)),
    },
    {
      id: "refundMode",
      field: "metadata.refundMode",
      title: "Refund Mode",
      width: 150,
      cell: createCellRenderer((data) =>
        convertEnumToLabel(data?.metadata?.refundMode)
      ),
    },
    {
      id: "createdAt",
      field: "createdAt",
      title: "Created At",
      width: 250,
      cell: createCellRenderer((data) => timeConverter(data?.createdAt)),
    },
    {
      id: "updatedAt",
      field: "updatedAt",
      title: "Updated At",
      width: 250,
      cell: createCellRenderer((data) => timeConverter(data?.updatedAt)),
    },
    {
      id: "updatedBy",
      field: "updatedBy",
      title: "Updated By",
      width: 150,
      cell: createCellRenderer((data) => convertEnumToLabel(data?.updatedBy)),
    },
    {
      id: "status",
      field: "action",
      title: "Status",
      width: 150,
      cell: createCellRenderer((data) => convertEnumToLabel(data?.action)),
    },
    {
      id: "amount",
      field: "metadata.amount",
      title: "Amount",
      width: 150,
      cell: createCellRenderer((data) => data?.metadata?.amount),
    },
  ];

  return (
    <>
      {loading ? (
        <div className="tw-flex tw-justify-center">
          <LoadingSpinner height={50} width={50} color="lightgray" />
        </div>
      ) : (
        <Grid data={disputeLogs}>
          {columns.map((column) => (
            <Column
              key={column.id}
              field={column.field}
              title={column.title}
              width={column.width}
              cell={column.cell}
            />
          ))}
        </Grid>
      )}
    </>
  );
}

export default DisputeLogs;
