import React, { useEffect, useState } from "react";
import ModalLayout from "../../../../common/ModalLayout";
import LoadingPanel from "../../../../common/LoadingPanel";
import axiosService from "../../../../../init/axios";
import { APIConfig } from "../../../../../utils/constants/api.constants";
import {
  checkMatchingRoles,
  getLabelValueObjectFromEnum,
  showErrorMessage,
  showToastMessage,
} from "../../../../../utils/helpers";
import { useSelector } from "react-redux";
import { userPermissions } from "../../../../../config/userPermissionConfig";
import RefundForm from "./RefundForm";

const AddOrUpdateRefund = ({
  selectedRefundDetails,
  tripId,
  refundStatusUpdate,
  onClose,
  closeModal,
  setRefresh,
  refundDataState,
  setRefundDataState,
  setSelectedRefundDetails,
  setRefundStatusUpdate
}) => {
  const [loading, setLoading] = useState(false);
  const [refundDetails, setRefundDetails] = useState({});

  const userRoles = useSelector((state) => state.auth?.userRoles);

  const closeRefundModal = () => {
    setRefundDataState({ ...refundDataState });
    setRefresh((prevState) => (!prevState ? true : true));
    setSelectedRefundDetails(null);
    setRefundStatusUpdate(false);
  };

  useEffect(() => {
    if (selectedRefundDetails) {
      const refundStatusString = !checkMatchingRoles(
        userRoles,
        userPermissions.refundModification
      )
        ? "refundStatusNonFinanceUser"
        : "refundStatusFinanceUser";
      const details = {
        ...selectedRefundDetails,
        refundReason: selectedRefundDetails?.reason,
        refundSubReason: selectedRefundDetails?.subReason,
        [refundStatusString]: selectedRefundDetails?.status,
      };
      const updatedDetails = getLabelValueObjectFromEnum(
        ["refundReason", "refundSubReason", refundStatusString],
        details,
        "trip"
      );
      setRefundDetails({
        ...updatedDetails,
        refundStatus: updatedDetails[refundStatusString],
      });
    }

    return () => setRefundDetails({});
  }, []);

  const handleSubmit = async (dataItem) => {
    setLoading(true);
    const formValues = {
      amount: dataItem?.amount,
      remarks: dataItem?.remarks,
      reason: dataItem?.refundReason?.value,
      subReason: dataItem?.refundSubReason?.value,
      infractionType: dataItem?.refundSubReason?.tag,
    };
    if (refundStatusUpdate) {
      formValues["id"] = dataItem?.id;
    }
    const url = refundStatusUpdate
      ? APIConfig.tripManagement.updateRefund(tripId, dataItem?.id)
      : APIConfig.tripManagement.createRefund(tripId);
    await axiosService[refundStatusUpdate ? "put" : "post"](
      process.env.REACT_APP_TRIP_BASE_URL + url,
      JSON.stringify(formValues),
      { "Content-Type": "application/json" }
    )
      .then((data) => {
        setLoading(false);
        showToastMessage(
          refundStatusUpdate
            ? "Refund updated successfully"
            : "Refund created successfully"
        );
        closeModal();
        closeRefundModal();
      })
      .catch((error) => {
        setLoading(false);
        showErrorMessage(error);
        setRefundDetails(dataItem);
      });
  };

  return (
    <ModalLayout title={"Add Refund"} onClose={()=>{closeModal();closeRefundModal();}}>
      <div className="refund-form-div" style={{ minWidth: 700 }}>
        {loading ? (
          <LoadingPanel />
        ) : (
          <RefundForm
            handleSubmit={handleSubmit}
            refundDetails={refundDetails}
          />
        )}
      </div>
    </ModalLayout>
  );
};

export default AddOrUpdateRefund;
