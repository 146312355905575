import React, { useState } from "react";
import { APIConfig } from "../../../../../utils/constants/api.constants";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import GridLoader from "../../../../common/GridLoader";
import {
  checkMatchingRoles,
  convertEnumToLabel,
  formatDateTime,
  formatDateTimeISO,
  getLabelFromEnum,
  timeConverter,
} from "../../../../../utils/helpers";
import { Button } from "@progress/kendo-react-buttons";
import { useSelector } from "react-redux";
import { userPermissions } from "../../../../../config/userPermissionConfig";

const RefundLogs = ({
  tripId,
  refresh,
  dataState,
  setDataState,
  onUpdateRefund,
  clientType,
  handleModalOpener,
  setRefundSelectedRow,
  setRefresh
}) => {
  const baseUrl =
    process.env.REACT_APP_TRIP_BASE_URL +
    APIConfig.tripManagement.getAllRefunds(tripId);
  const [refundLogs, setRefundLogs] = useState({
    data: [],
    total: 0,
  });
  const userRoles = useSelector((state) => state.auth?.userRoles);

  const CommandCell = (props) => (
    <td>
      <Button
        themeColor={"info"}
        disabled={
          !checkMatchingRoles(userRoles, userPermissions.refundModification) ||
          props?.dataItem?.refundMode !== "MANUAL"
        }
        onClick={(e) => onUpdateRefund(e, { ...props })}
        fillMode="link"
      >
        Update
      </Button>
    </td>
  );

  const CancelRefundCell = (props) => (
    <td>
 <Button
  themeColor={"info"}
  onClick={() => {
    handleModalOpener("cancelRefundModal");
    setRefundSelectedRow(props?.dataItem);
    setRefresh((prevState) => (!prevState ? false : false));
  }}
  fillMode="link"
  disabled={
    props?.dataItem?.refundMode !== "PAYOUT_LINK" || 
    (props?.dataItem?.status !== "PENDING" && props?.dataItem?.status !== "INITIATED")
  }
>
  Cancel
</Button>

    </td>
  );
  const DetailsCell = (props) => (
    <td>
      <Button
        themeColor={"info"}
        onClick={() => {
          handleModalOpener("refundLogDetailsModal");
          setRefundSelectedRow(props?.dataItem);
        }}
        fillMode="link"
      >
        More Details
      </Button>
    </td>
  );

  const GenericCell = (props, transformFn) => {
    const { dataItem, field } = props;
    return <td>{transformFn(dataItem?.[field])}</td>;
  };

  const isClientB2C = clientType === "B2C";

  const columns = [
    {
      id: "id",
      field: "id",
      title: "Id",
      width: 150,
    },
    {
      id: "amount",
      field: "amount",
      title: "Refund Amount",
      width: 150,
    },
    {
      id: "refundReason",
      field: "reason",
      title: "Refund Reason",
      width: 250,
      cell:(props)=>GenericCell(props, convertEnumToLabel),
    },
    {
      id: "refundSubReason",
      field: "subReason",
      title: "Refund Sub Reason",
      width: 250,
      cell:(props)=>GenericCell(props, convertEnumToLabel),
    },
    {
      id: "updatedAt",
      field: "updatedAt",
      title: "Updated At",
      width: 200,
      cell:(props)=>GenericCell(props, timeConverter),
    },
    {
      id: "transactionId",
      field: "transactionId",
      title: "Transaction Id",
      width: 200,
    },
    {
      id: "updatedBy",
      field: "updatedBy",
      title: "Updated By",
      width: 150,
    },
    {
      id: "status",
      field: "status",
      title: "Status",
      width: 150,
      cell:(props)=>GenericCell(props, convertEnumToLabel),
    },
    {
      id: "refundMode",
      field: "refundMode",
      title: "Refund Mode",
      width: 150,
      cell:(props)=>GenericCell(props, convertEnumToLabel),
    },
    {
      id: "updateButton",
      cell: CommandCell,
      title: "Update Status",
      width: 150,
    },
    isClientB2C && {
      id: "cancelRefundButton",
      cell: CancelRefundCell,
      title: "Cancel/Refund",
      width: 150,
    },
    isClientB2C && {
      id: "detailsButton",
      cell: DetailsCell,
      title: "Details",
      width: 150,
    },
  ];

  const dataStateChange = (e) => {
    setDataState(e.dataState);
  };

  const dataReceived = (dataObj) => {
    const updatedLogs = dataObj.data?.refundDetails?.map((log) => {
      const details = {
        ...log,
        refundReason: log?.reason,
        refundSubReason: log?.subReason,
        refundStatus: log?.status,
      };
      const updatedLogDetails = getLabelFromEnum(
        ["refundReason", "refundSubReason", "refundStatus"],
        details,
        "trip"
      );
      return { ...updatedLogDetails };
    });
    setRefundLogs({ ...dataObj, data: updatedLogs });
  };

  return (
    <div className="refund-div">
      <Grid
        filterable={false}
        sortable={false}
        pageable={false}
        {...dataState}
        data={refundLogs}
        onDataStateChange={dataStateChange}
      >
        {columns.filter(Boolean).map((column) => (
          <Column
            key={column?.id}
            field={column?.field}
            title={column?.title}
            width={column.width}
            cell={column?.cell}
          />
        ))}
      </Grid>

      <GridLoader
        baseUrl={baseUrl}
        columns={columns}
        refreshTable={refresh}
        dataState={dataState}
        onDataReceived={dataReceived}
      />
    </div>
  );
};

export default RefundLogs;
