import React, { useEffect, useState } from "react";
import ModalLayout from "../../../../common/ModalLayout";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { APIConfig } from "../../../../../utils/constants/api.constants";
import axiosService from "../../../../../init/axios";
import { convertEnumToLabel, showErrorMessage, timeConverter } from "../../../../../utils/helpers";
import LoadingSpinner from "../../../../common/LoadingSpinner";

function RefundLogDetailsModal({ closeModal, tripId, refundSelectedRow }) {
  const [loading, setLoading] = useState(false);
  const [refundDetails, setRefundDetails] = useState([]);

  const fetchRefundLogDetails = async () => {
    setLoading(true);
    await axiosService
      .get(
        process.env.REACT_APP_TRIP_BASE_URL +
          APIConfig.tripManagement.getRefundDetailsData(
            tripId,
            refundSelectedRow?.transactionId
          )
      )
      .then((data) => {
        if (data?.data) {
          setRefundDetails(data?.data);
        }
      })
      .catch((error) => {
        showErrorMessage(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    fetchRefundLogDetails();
  }, []);

  const GenericCell = (props, transformFn) => {
    const { dataItem, field } = props;
    return <td>{transformFn(dataItem?.[field])}</td>;
  };

  const columns = [
    {
      id: "id",
      field: "id",
      title: "Id",
      width: 100,
    },
    {
      id: "amount",
      field: "amount",
      title: "Refund Amount",
      width: 150,
    },
    {
      id: "reason",
      field: "reason",
      title: "Refund Reason",
      width: 250,
      cell:(props)=>GenericCell(props, convertEnumToLabel),
    },
    {
      id: "subReason",
      field: "subReason",
      title: "Refund Sub Reason",
      width: 250,
      cell:(props)=>GenericCell(props, convertEnumToLabel),
    },
    {
      id: "createdAt",
      field: "createdAt",
      title: "Created At",
      width: 200,
      cell:(props)=>GenericCell(props, timeConverter),
    },
    {
      id: "transactionId",
      field: "transactionId",
      title: "Transaction Id",
      width: 200,
    },
    {
      id: "createdBy",
      field: "createdBy",
      title: "Created By",
      width: 150,
    },
    {
      id: "status",
      field: "status",
      title: "Status",
      width: 150,
      cell:(props)=>GenericCell(props, convertEnumToLabel),
    },
  ];

  return (
    <ModalLayout
      title={`Refund Id - ${refundSelectedRow?.transactionId}`}
      onClose={closeModal}
    >
      <div style={{ width:1000 }}>
        {loading ? (
          <div className="tw-flex tw-justify-center">
            <LoadingSpinner height={50} width={50} color="lightgray" />
          </div>
        ) : (
          <Grid data={refundDetails}>
            {columns?.map((column) => (
              <Column
                key={column?.id}
                field={column?.field}
                title={column?.title}
                width={column?.width}
                cell={column?.cell}
              />
            ))}
          </Grid>
        )}
      </div>
    </ModalLayout>
  );
}

export default RefundLogDetailsModal;
