import { toast } from "react-toastify";
import { DriverConfig } from "../config/driverConfig";
import { TripConfig } from "../config/tripConfig";
import FilterDropDown from "../components/common/FilterDropDown";
import { CouponConfig } from "../config/couponConfig";
import FilterMultiSelectDropDown from "../components/common/FilterMultiSelectDropDown";
import { SubscriptionConfig } from "../config/subscriptionConfig";
import axiosService from "../init/axios";
import { ADMIN_ROUTES, CLIENT_ROUTES } from "../Routing";

export const dateAndTimeToRequiredFormat = (date) => {
  const day = prefixZeroToValue(date.getDate());
  const month = prefixZeroToValue(Number(date.getMonth() + 1));
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  return [day, month, year, hours, minutes, seconds];
};

export const prefixZeroToValue = (value) => {
  if (String(value)?.length === 1) return "0" + value;
  else return value;
};

export const initialFilter = {
  logic: "or",
};

export const saveToLocalStorage = (key, value) => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (err) {
    console.log(err);
  }
};

export const getFromLocalStorage = (key) => {
  let val = localStorage.getItem(key);
  try {
    if (val) return JSON.parse(val);
  } catch (err) {
    return null;
  }
  return val;
};

export const removeFromLocalStorage = (key) => {
  localStorage.removeItem(key);
};

export const clearLocalStorage = () => {
  localStorage.clear();
};

export const showToastMessage = (
  message,
  isSuccess = true,
  autoClose = 3000
) => {
  if (isSuccess) {
    toast.success(message, {
      hideProgressBar: true,
      autoClose: autoClose,
    });
  } else {
    toast.error(message, {
      hideProgressBar: true,
      autoClose: autoClose,
      closeOnClick: true,
    });
  }
};

export const showToastInfoMessage = (message, autoClose = 3000) => {
  toast.info(message, {
    hideProgressBar: true,
    autoClose: autoClose,
    closeOnClick: true,
  });
};

export const showFirebaseErrorMessage = (error) => {
  if (error.code) {
    showToastMessage(
      "Invalid login: " + getRefinedFirebaseAuthErrorMessage(error?.code),
      false
    );
  } else {
    showToastMessage("Invalid login", false);
  }
};

export const getRefinedFirebaseAuthErrorMessage = (errorMesssage) => {
  return errorMesssage.split("/")[1].split("-").join(" ");
};

export const showErrorMessage = (error) => {
  if (error && error.response?.data?.error?.message) {
    showToastMessage(error.response?.data?.error?.message, false);
  } else if (error && error.response?.data?.msg) {
    showToastMessage(error.response?.data?.msg, false);
  } else if (error && error.response?.data?.error) {
    showToastMessage(error.response?.data?.error, false);
  } else {
    showToastMessage(error.message, false);
  }
};

export const getImage = (url) => {
  if (url) {
    return url;
  } else {
    return "https://cdn.vectorstock.com/i/preview-1x/65/30/default-image-icon-missing-picture-page-vector-40546530.jpg";
  }
};

export const transformFilters = (columns, { filters }) => {
  const KENDO_FILTER_VALUE_TYPES = ["string", "number", "boolean"];
  const UPPERCASE_COLUMNS = [
    "fullName",
    "customerName",
    "tripId",
    "driverId",
    "clientName",
    "driverName",
  ];
  if (!filters) return {};

  let filterParams = filters.reduce((acc, { field, value }) => {
    if (value === "") {
      return acc;
    }
    const regex = /[A-Za-z0-9_]/;
    if (!regex.test(value)) {
      showToastMessage(
        "Special characters are not allowed in filter search",
        false
      );
      return acc;
    }
    const fieldColumn = columns.find((col) =>
      col.field ? field === col.field : field === col.id
    );
    const fieldFilterParam = fieldColumn["filterKey"];
    const fieldFilterType = fieldColumn["filterType"];
    const fieldFilterOperator =
      fieldColumn["defaultFilterOperator"] === "eqIn"
        ? "in"
        : fieldColumn["defaultFilterOperator"];
    acc[fieldFilterParam] = KENDO_FILTER_VALUE_TYPES.includes(typeof value)
      ? (UPPERCASE_COLUMNS.includes(fieldColumn?.field)
          ? stringToUpperCase(value)
          : value) +
        ":" +
        fieldFilterOperator +
        ":" +
        fieldFilterType
      : value.value + ":" + fieldFilterOperator + ":" + fieldFilterType;
    return acc;
  }, {});

  return filterParams;
};

export const convertObjectToParams = (params) => {
  if (typeof params === "string") {
    return params;
  }
  let finalParams = "";
  Object.keys(params).forEach((key, index) => {
    if (index === 0) {
      finalParams += "?";
    }
    finalParams += key + "=" + params[key];
    if (index + 1 !== Object.keys(params).length) {
      finalParams += "&";
    }
  });
  return finalParams;
};

export const getLabelFromEnum = (keys, dataSet, config) => {
  let configFile;
  switch (config) {
    case "trip":
      configFile = TripConfig;
      break;

    case "driver":
      configFile = DriverConfig;
      break;

    case "coupon":
      configFile = CouponConfig;
      break;

    case "subscription":
      configFile = SubscriptionConfig;
      break;

    default:
      break;
  }

  if (configFile) {
    for (const key of keys) {
      if (dataSet[key]) {
        dataSet[key] = configFile[key]?.filter(
          (item) => item.value === dataSet[key]
        )[0]?.label;
      }
    }
    return dataSet;
  }
};

export const getLabelValueObjectFromEnum = (keys, dataSet, config) => {
  let configFile;
  switch (config) {
    case "trip":
      configFile = TripConfig;
      break;

    case "driver":
      configFile = DriverConfig;
      break;

    case "coupon":
      configFile = CouponConfig;
      break;

    default:
      break;
  }

  if (configFile) {
    for (const key of keys) {
      if (dataSet[key] || dataSet[key]?.length > 0) {
        dataSet[key] = configFile[key]?.filter(
          (item) => item.value === dataSet[key]
        )[0];
      } else {
        if (key === "driverPayoutType") {
          dataSet[key] = { label: "Select", value: "" };
        } else {
          dataSet[key] = configFile[key][0];
        }
      }
    }
    return dataSet;
  }
};

export const CategoryFilterCell = (props, column, width) => (
  <FilterDropDown

    {...props}
    {...column}
    data={column.data}
    defaultItem={{ label: "Select Filter", value: "" }}
    width={width}
  />
);

export const CategoryFilterMultiSelectCell = (props, column, width) => (
  <FilterMultiSelectDropDown
    {...props}
    {...column}
    data={column.data}
    width={width}
  />
);

export const convertEnumToLabel = (enumStr) => {
  if (!enumStr) return;
  return enumStr
    .split("_")
    .map((item) => item[0] + item?.substring(1)?.toLowerCase())
    .join(" ");
};

export const convertLabelToEnum = (labelStr) => {
  return labelStr.toUpperCase().split(" ").join("_");
};

export const replaceNullValues = (value, returnValue) => {
  if (value) return value;
  return returnValue ? returnValue : "N.A";
};

export const stringToUpperCase = (value) => {
  if (value) {
    return value?.trim().toUpperCase();
  }
};

export const stringToSentenceCase = (value) => {
  return value
    ?.trim()
    ?.split(" ")
    ?.map(
      (word) =>
        word?.toLowerCase()?.at(0)?.toUpperCase() +
        word?.toLowerCase()?.slice(1)
    )
    ?.join(" ");
};

export const dateToDaysHoursMinutesSeconds = (finalDate, initialDate) => {
  let dateString = "";
  const difference =
    new Date(finalDate).getTime() - new Date(initialDate).getTime();
  const days = new Date(difference).toISOString().slice(8, 10);
  const hoursAndMinutes = new Date(difference)
    .toISOString()
    .slice(11, 19)
    .split(":");
  if (days.toString() !== "01") {
    dateString +=
      Number(days) - 1 === 1
        ? Number(days) - 1 + " day"
        : Number(days) - 1 + " days";
  }
  if (Number(hoursAndMinutes[0]) > 0) {
    dateString +=
      Number(hoursAndMinutes[0]) === 1
        ? hoursAndMinutes[0] + " hour "
        : hoursAndMinutes[0] + " hours ";
  }
  if (Number(hoursAndMinutes[1]) > 0) {
    dateString +=
      Number(hoursAndMinutes[1]) === 1
        ? hoursAndMinutes[1] + " minute "
        : hoursAndMinutes[1] + " minutes ";
  }
  if (Number(hoursAndMinutes[2]) > 0) {
    dateString +=
      Number(hoursAndMinutes[2]) === 1
        ? hoursAndMinutes[2] + " second "
        : hoursAndMinutes[2] + " seconds ";
  }

  return dateString;
};

export const convertMinutesToHoursMinutesFormat = (minutes) => {
  if (minutes === null) return "N.A";
  if (minutes < 60) {
    return minutes + " minutes";
  }
  let hourString = " hour",
    minuteString = " minute";
  let hours = Math.floor(minutes / 60);
  let actualMinutes = minutes % 60;
  if (hours > 1) hourString = " hours";
  if (actualMinutes === 0) return hours + hourString;
  if (actualMinutes > 1) minuteString = " minutes";
  return hours + hourString + " " + actualMinutes + minuteString;
};

export const handleOnWheel = (event) => {
  event.stopPropagation();
};

export const downloadFileFromAPI = (response, name) => {
  const url = window.URL.createObjectURL(new Blob([response]));
  const link = document.createElement("a");
  link.href = `${url}`;
  link.setAttribute("download", name + ".csv");
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const removeExistingFileOnFileUpload = (
  e,
  setFileStateValue,
  inputRef
) => {
  e.target.value = "";
  setFileStateValue("");
  inputRef.current.value = "";
};

export const checkMatchingRoles = (currentRoles, designatedRoles) => {
  const currentRolesSet = new Set(currentRoles);
  const commonRoles = designatedRoles.filter((item) =>
    currentRolesSet.has(item)
  );
  return commonRoles.length > 0;
};
// Helper to format addresses
export function formatAddress(address) {
  if (address?.formattedAddress) return address?.formattedAddress;
  const {
    houseNo = "",
    locality = "",
    landmark = "",
    city = "",
    state = "",
    pincode = "",
    addressType = "",
  } = address;

  let formattedAddress = "";

  if (houseNo) formattedAddress += houseNo + ", ";
  if (locality) formattedAddress += locality + ", ";
  if (landmark) formattedAddress += "Near " + landmark + ", ";
  if (city) formattedAddress += city + ", ";
  if (state) formattedAddress += state + " ";
  if (pincode) formattedAddress += pincode + ", ";
  // if (addressType) formattedAddress += "(" + addressType + ")";

  formattedAddress = formattedAddress.replace(/,\s*$/, "");

  return formattedAddress;
}

// Helper to convert time to a readable format
export const timeConverter = (time) => {
  if (!time) return;
  return new Date(time).toLocaleString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
};

// Helper to format date and time
export function formatDateTime(dateTimeStr) {
  if (!dateTimeStr) return "N.A.";
  const date = new Date(dateTimeStr);
  const formatHoursMinutes = (hours, minutes) => {
    const period = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    return `${hours}:${minutes} ${period}`;
  };

  const hours = date.getHours();
  const minutes = date.getMinutes();

  return formatHoursMinutes(hours, minutes);
}

// Helper to get label by value from an array of objects
export function getLabelByValue(value, array) {
  const statusItem = array.find((item) => item.value === value);
  return statusItem ? statusItem.label : null;
}

// !------------ New helpers ---------------!

// Helper to calculate time ago from a timestamp
export function calculateTimeAgo(timestamp) {
  const now = new Date();
  const pastDate = new Date(timestamp);
  const difference = now - pastDate;
  const seconds = Math.floor(difference / 1000);

  if (seconds < 60) {
    return `${seconds} seconds ago`;
  } else if (seconds < 3600) {
    const minutes = Math.floor(seconds / 60);
    return `${minutes} minutes ago`;
  } else if (seconds < 86400) {
    const hours = Math.floor(seconds / 3600);
    return `${hours} hours ago`;
  } else {
    const days = Math.floor(seconds / 86400);
    return `${days} days ago`;
  }
}

// Helper to render driver status tags
export function driverStatusTags(status) {
  if (!status) return <span className="status border">N.A.</span>;
  let lowerCaseStatus = status?.toLowerCase();
  return (
    <span className={`status ${lowerCaseStatus}`}>
      {changeDriverStatusTagsValues(lowerCaseStatus)}
    </span>
  );
}

// Helper to change driver status tag values
export function changeDriverStatusTagsValues(status) {
  switch (status?.toUpperCase()) {
    case "ONLINE":
      return "Online";
    case "IN_TRIP":
      return "In Trip";
    case "TRIP_ACCEPTANCE_TIMER_RUNNING":
      return "TATR";
    case "OUT_OF_SERVICEABLE_AREA":
      return "OOSA";
    case "OFFLINE":
      return "Offline";
    case "CHECK_IN":
      return "Check In";
    case "CHECK_OUT":
      return "Check Out";
    case "CHECK_IN_TIMER_RUNNING":
      return "CITR";
    default:
      return status;
  }
}

// Helper to render driver status tags on a map
export function driverStatusTagsMap(status) {
  let lowerCaseStatus = status?.toLowerCase();
  if (!status) return <span className="mapStatus border">N.A.</span>;

  return (
    <div className={`mapStatus tw-whitespace-nowrap ${lowerCaseStatus}`}>
      <span className={`tw-w-2 tw-aspect-square tw-rounded-full `}></span>
      {changeDriverStatusTagsValues(lowerCaseStatus)}
    </div>
  );
}

// Helper to get adherence image source
export const getImgSrc = async (basePath = "") => {
  try {
    const response = await axiosService.get(
      `${
        process.env.REACT_APP_DRIVER_BASE_URL
      }/admin/document?file-path=${encodeURIComponent(basePath)}`
    );
    if (response && response.data) {
      return response?.data?.preSignedUrl;
    }
  } catch (err) {}
};

// Helper to format date
export const formatDate = (dateString) => {
  if (!dateString) return "Select Date";
  const date = new Date(dateString);
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();
  return `${day} ${month}, ${year}`;
};

// Helper to convert timestamp to 12-hour format
export function convertTo12HourFormat(timestamp) {
  if (!timestamp) return;
  const date = new Date(timestamp);
  return date.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
}

export function formatDateTimeISO(isoString) {
  if (!isoString) return "";
  const date = new Date(isoString);
  const [day, month, year] = [
    date.getDate().toString().padStart(2, "0"),
    (date.getMonth() + 1).toString().padStart(2, "0"),
    date.getFullYear(),
  ];
  const [hours, minutes] = [
    date.getHours().toString().padStart(2, "0"),
    date.getMinutes().toString().padStart(2, "0"),
  ];
  return `${day}-${month}-${year} ${hours}:${minutes}`;
}

export function getObjectByKey(arr = [], key, value) {
  return arr?.find((obj) => obj[key] === value);
}

export function calculateTripDuration(startTime, endTime = new Date()) {
  const start = new Date(startTime);
  const end = endTime instanceof Date ? endTime : new Date(endTime);

  const diffInMs = end - start;

  const totalMinutes = Math.floor(diffInMs / (1000 * 60));

  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  let sentence = "";

  if (hours > 0) {
    sentence += `${hours} hour${hours > 1 ? "s" : ""}`;
  }
  if (minutes > 0) {
    if (hours > 0) {
      sentence += ` `;
    }
    sentence += `${minutes} minute${minutes > 1 ? "s" : ""}`;
  }

  if (!hours && !minutes) {
    sentence = "0 minutes";
  }

  return sentence;
}

export function isNotEmptyString(value) {
  return typeof value === "string" && value.trim().length > 0;
}

export function addHoursToTimestamp(startTime, noOfHours) {
  const startDate = new Date(startTime);
  const endDate = new Date(startDate.getTime() + noOfHours * 60 * 60 * 1000);

  return endDate.toISOString();
}

export function conditionalRendering(arr, state, children,elseReturn) {
  // if "state" exists in the "array" only then show the component
  if (!arr.includes(state)) return <>{elseReturn ? elseReturn : null}</>;
  //
  return children;
}

export function toDateString(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}

export const authorizatedRoutes = (route, clientId, isAuthenticated) => {
  if (isAuthenticated && clientId == false) {
    return ADMIN_ROUTES.some((adminRoute) => route.startsWith(adminRoute));
  } else {
    return CLIENT_ROUTES.some((clientRoute) => route.startsWith(clientRoute));
  }
};

export function getFirstLetter(str) {
  if (str && str.length > 0) {
    return str.charAt(0);
  }
  return "";
}

export function findObjectDifference(obj1, obj2) {
  // Check if both objects are of type object
  if (typeof obj1 !== "object" || typeof obj2 !== "object") {
    return obj1 !== obj2;
  }

  // If either object is null
  if (obj1 === null || obj2 === null) {
    return obj1 !== obj2;
  }

  // Get keys of both objects
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  // Check if number of keys are the same
  if (keys1.length !== keys2.length) {
    return true; // The objects have different number of keys
  }

  // Iterate through the keys
  for (let key of keys1) {
    // Check if the key exists in both objects
    if (!keys2.includes(key)) {
      return true; // Key is missing in the second object
    }

    // Recursively check if the values are the same
    const value1 = obj1[key];
    const value2 = obj2[key];

    // If values are objects, recurse, otherwise compare directly
    const isDifferent =
      typeof value1 === "object" && typeof value2 === "object"
        ? findObjectDifference(value1, value2)
        : value1 !== value2;

    if (isDifferent) {
      return true; // A difference has been found
    }
  }

  return false; // No differences found
}

export function calculateTotalWorkedTime(sessions) {
  const totalWorkedTimeInMinutes = sessions.reduce((total, session) => {
    const sessionStartTime = new Date(session.sessionStartTime);
    const sessionEndTime = session.sessionEndTime
      ? new Date(session.sessionEndTime)
      : new Date();
    const sessionDuration = (sessionEndTime - sessionStartTime) / (1000 * 60);
    return total + sessionDuration;
  }, 0);

  const hours = Math.floor(totalWorkedTimeInMinutes / 60);
  const minutes = Math.floor(totalWorkedTimeInMinutes % 60);
  const hourText = hours === 1 ? "hour" : "hours";
  const minuteText = minutes === 1 ? "minute" : "minutes";

  if (hours > 0 && minutes > 0) {
    return `Online time: ${hours} ${hourText} and ${minutes} ${minuteText}`;
  } else if (hours > 0) {
    return `Online time: ${hours} ${hourText}`;
  } else {
    return `Online time: ${minutes} ${minuteText}`;
  }
}

export function getFormattedDate() {
  const today = new Date();
  let yyyy = today.getFullYear();
  let mm = today.getMonth() + 1; 
  let dd = today.getDate();
  const hour = today.getHours();
  if (hour >= 22) {
    today.setDate(dd + 1);
    yyyy = today.getFullYear();
    mm = today.getMonth() + 1;
    dd = today.getDate();
  }

  const formattedDate = `${yyyy}-${String(mm).padStart(2, "0")}-${String(
    dd
  ).padStart(2, "0")}`;
  return formattedDate;
}